<template>
<div class="wraper-footer">
  <div id="page-footer" :style="{'background-color' : this.$store.state.customBranding.bgColor}">
    <div class="mail" :style="{'background-color' : this.$store.state.customBranding.bgColor}">
      <img src="../assets/icons/ic-mail.png" />
      <a href="mailto:help@zonyx.tech">help@zonyx.tech</a>
    </div>
    <div class="nagiLogoFooter" :style="{'background-color' : this.$store.state.customBranding.bgColorLogoFooter}" >
      <a href="https://zonyx.tech/nagi" target="_blank">
        <img style="max-height: 50px" src="../assets/icons/nagi_white.png" />
      </a>
    </div>
    <div class="privacy" :style="{'background-color' : this.$store.state.customBranding.bgColor, 'color' : this.$store.state.customBranding.privacyColor}">
      <router-link to="privacy-policy" :style="{'color' : this.$store.state.customBranding.privacyColor}">{{
        $t("footer.privacy_policy") | capitalize
      }}</router-link>
      |
      <router-link to="cookies" :style="{'color' : this.$store.state.customBranding.privacyColor}">{{
        $t("footer.cookies_policy") | capitalize
      }}</router-link>
      |
      <router-link to="terms-conditions" :style="{'color' : this.$store.state.customBranding.privacyColor}">{{
        $t("footer.legal_advice") | capitalize
      }}</router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "NagiFooter",
};
</script>

<style>
#page-footer {
  width: 100%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
  margin-top: auto;
  background-color: #030d2c;
  font-size: medium;
  padding-top: 5px;
  padding-bottom: 5px;
}
.wraper-footer{
  display: flex;
  align-content: flex-end;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
}
.mail {
  background-color: #030d2c;
  display: flex;
  margin: auto;
}

.mail a {
  color: white;
  text-decoration: none;
  font-weight: 100;
  justify-self: start;
}

.mail img {
  height: 1.3rem;
  margin-right: 8px;
}

.privacy {
  background-color: #030d2c;
  justify-self: end;
  /* margin-right: 30%; */
}

.privacy a {
  color: #6f8396;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  #page-footer {
    grid-template-columns: 100%;
  }

  .mail {
    justify-self: center;

    margin-top: 8px;
  }
  .privacy {
    justify-self: center;
    width: 100%;
    height: 100%;
    margin: 10px;
    margin-bottom: 8px;
  }
  .nagiLogoFooter {
    height: 100%;
    width: 100%;
    justify-self: center;
    margin-bottom: 6px;
    margin-top: 8px;
  }
  .wraper-footer{
    flex: 1;
  }
}
</style>
